import React from 'react'

const BannerSection = (props) => {

    return(
        <section className="wpo-banner-section">
            <h4>There is only one happiness in this life, to love and be loved</h4>
        </section>
    )
}

export default BannerSection;