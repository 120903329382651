import React from 'react'
import SectionTitleS2 from '../SectionTitleS2'
import sImg1 from '../../images/qr-nhung.png'
import sImg2 from '../../images/qr_dung.png'

const HopMungCuoi = (props) => {
    return (
        <section className={`wpo-event-section ${props.eClass}`} id="info">
            <div className="container">
                <SectionTitleS2 MainTitle={'Hộp Mừng Cưới'} />
                <div className="wpo-event-wrap">
                    <div className="row justify-content-center">
                      <div className="col col-md-4 col-12">
                          <div className="">
                              <div className="qr-code">
                                <img src={sImg1} alt=""/>
                              </div>
                          </div>
                      </div>
                      <div className="col col-md-4 col-12">
                          <div className="flex">
                              <div className="qr-code">
                                <img src={sImg2} alt=""/>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HopMungCuoi;