import React from 'react'
import SectionTitleS2 from '../SectionTitleS2'
import sImg1 from '../../images/couple/love-story/5.png'
import sImg2 from '../../images/couple/love-story/4.png'
// import sImg3 from '../../images/portfolio/3.jpg'
// import sImg4 from '../../images/portfolio/4.jpg'

// import shape from '../../images/story/shape.png'




const StorySection = (props) => {
    return(
        <section className="story-section section-padding" id="story">
            <div className="container">
                <SectionTitleS2 MainTitle={'Ngày Cưới'}/>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="story-timeline">
                            <div className="round-shape"></div>
                            <div className="row">
                                <div className="col col-lg-6 col-12 text-holder revert-direction">
                                    <div className="story-text right-align-text">
                                        <h3>Lễ ăn hỏi</h3>
                                        <span className="date">Sep 22,2024</span>
                                        {/* <p>Quả cau, khơi trầu, duyên ta thắm lại. Sắt son mãi chẳng rời. Thời khắc này, hai gia đình chính thức trở thành thông gia, đánh dấu sự khởi đầu của một mối quan hệ mới. </p> */}
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12">
                                    <div className="img-holder">
                                        <img src={sImg1} alt="" className="img img-responsive"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <div className="img-holder right-align-text left-site">
                                        <img src={sImg2} alt="" className="img img-responsive"/>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 text-holder">
                                    <span className="heart">
                                        <i className="fi flaticon-heart"></i>
                                    </span>
                                    <div className="story-text">
                                        <h3>Lễ cưới</h3>
                                        <span className="date">Sep 22,2024</span>
                                        {/* <p>Hai con người xa lạ chính thức trở thành một gia đình, cùng nhau xây dựng tổ ấm, vun đắp tình yêu, cùng nhau vượt qua mọi thử thách và khó khăn. Mỗi ngày mới đều sẽ là một hành trình đầy ý nghĩa và hạnh phúc.</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col offset-lg-6 col-lg-6 col-12 text-holder">
                                    <span className="heart">
                                        <i className="fi flaticon-wedding-rings"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StorySection;