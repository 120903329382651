import React from 'react'
import SectionTitleS2 from '../SectionTitleS2'
import pImg1 from '../../images/portfolio/pre-wedding/ngang/1.jpg'
import pImg2 from '../../images/portfolio/pre-wedding/doc/20.jpg'
import pImg3 from '../../images/portfolio/pre-wedding/doc/5.jpg'
import pImg4 from '../../images/portfolio/pre-wedding/doc/2.jpg'
import pImg5 from '../../images/portfolio/pre-wedding/doc/8.jpg'
import pImg6 from '../../images/portfolio/pre-wedding/doc/11.jpg'
import pImg7 from '../../images/portfolio/pre-wedding/doc/10.jpg'
import pImg8 from '../../images/portfolio/pre-wedding/doc/15.jpg'
import pImg9 from '../../images/portfolio/pre-wedding/doc/16.jpg'
import pImg10 from '../../images/portfolio/pre-wedding/ngang/2.jpg'
import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'


const Portfolios_1 = [
    {
        image:pImg1,
    },
    {
        image:pImg2,
    },
    {
        image:pImg3,
    },
] 

const Portfolios_2 = [
    {
        image:pImg4,
    },
    {
        image:pImg5,
    },
    {
        image:pImg6,
    },
    {
        image:pImg7,
    },
]

const Portfolios_3 = [
    {
        image:pImg8,
    },
    {
        image:pImg9,
    },
    {
        image:pImg10,
    },
]

const PortfolioSection = (props) => {
    return(
        <section className={`wpo-portfolio-section-s2 section-padding ${props.pSclass}`} id="gallery">
            <div className="container-fluid">
                <SectionTitleS2 MainTitle={'Pre-Wedding'}/>
                <div className="sortable-gallery">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="portfolio-grids gallery-container clearfix first_row">
                                {Portfolios_1.map((portfolio, pitem) => (
                                    <div className="grid" key={pitem}>
                                        <div className="img-holder">
                                            <ReactFancyBox
                                                thumbnail={portfolio.image}
                                                image={portfolio.image}
                                                />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="portfolio-grids gallery-container clearfix second_row">
                                {Portfolios_2.map((portfolio, pitem) => (
                                    <div className="grid" key={pitem}>
                                        <div className="img-holder">
                                            <ReactFancyBox
                                                thumbnail={portfolio.image}
                                                image={portfolio.image}
                                                />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="portfolio-grids gallery-container clearfix third_row">
                                {Portfolios_3.map((portfolio, pitem) => (
                                    <div className="grid" key={pitem}>
                                        <div className="img-holder">
                                            <ReactFancyBox
                                                thumbnail={portfolio.image}
                                                image={portfolio.image}
                                                />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PortfolioSection;